.post {
  padding: 30px;
}

.post__featured {
  border-bottom: 10px solid #f8821a;
  position: relative;
  padding-bottom: 56.25%;
}

.post__featured__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.post__title {
  font-size: 3.75rem;
  font-weight: 800;
  margin: 40px 0 30px 0;
}

.post__image {
  background-position: center;
  background-size: cover;
  height: 400px;
  border-bottom: 10px solid #f8821a;
}

.post__summary,
.post__content {
  margin: 0 0 100px 0;
}

.post__related__title {
  font-size: 2.5rem;
  font-weight: 800;
  margin: 0 0 20px 0;
}

@media only screen and (max-width: 768px) {
  .post {
    padding: 20px;
  }

  .post__title {
    font-size: 1.75rem;
  }

  .post__summary,
  .post__content {
    margin: 0 0 50px 0;
  }
}