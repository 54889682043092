.page {
  padding: 30px;
}

.page__title {
  font-size: 3.75rem;
  font-weight: 800;
  margin: 10px 0 30px 0;
}

@media only screen and (max-width: 768px) {

  .page {
    padding: 20px;
  }
}