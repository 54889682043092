.category {
  padding: 30px;
}

.category__featured {
  border-bottom: 10px solid #f8821a;
  position: relative;
  padding-bottom: 56.25%;
}

.category__featured__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.category__title {
  font-size: 3.75rem;
  font-weight: 800;
  margin: 40px 0 30px 0;
}

.category__author {
  display: flex;
  margin: 0 0 50px 0;
}

.category__author__image {
  background-color: #ffffff;
  background-size: cover;
  border-radius: 999px;
  flex-shrink: 0;
  height: 200px;
  width: 200px;
}

.category__author__summary {
  line-height: 1.5rem;
  padding: 20px 20px 20px 40px;
}

.category__post {
  background: #fff;
  box-shadow: 0 0 20px #eee;
  display: flex;
  margin: 0 0 30px 0;
  padding: 20px;
}

.category__post__link {
  color: #000;
  text-decoration: none;
}

.category__post__image {
  background: #f4f4f4;
  background-size: cover;
  flex-shrink: 0;
  height: 225px;
  width: 400px;
}

.category__post__content {
  padding: 20px 30px;
}

.category__post__content h2 {
  font-size: 2.25rem;
  font-weight: 800;
  margin: 0 0 10px 0;
}

.category__post__content p {
  font-size: 1rem;
}

.category__post__link:hover .category__post {
  box-shadow: 0 0 20px #ddd;
}


@media only screen and (max-width: 768px) {
  .category {
    padding: 20px;
  }

  .category__author {
    display: block;
  }

  .category__author__image {
    height: 150px;
    width: 150px;
  }

  .category__author__summary {
    padding: 20px 0;
  }

  .category__post {
    display: block;
  }

  .category__post__image {
    display: block;
    padding-bottom: 56.25%;
    height: auto;
    width: 100%;
  }
}