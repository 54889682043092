* {
  border: 0;
  margin: 0;
  padding: 0;
}

html {
  background: #fff;
  height: 100%;
}

body {
  background: #f9f9f9;
  font-family: "Open Sans";
  font-size: 16px;
  min-height: 100%;
}

.main {
  display: flex;
  height: 100%;
}

.main video {
  height: 100%;
  object-fit: cover;
  width: 100vw;
}

.video-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  overflow: hidden;
}

.video-wrapper iframe {
  width: 100vw;
  height: 56.25vw;
  min-height: 100vh;
  min-width: 177.77vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main__content {
  color: #fff;
  font-size: 1.3rem;
  padding: 50px;
  position: absolute;
  text-shadow: rgba(0, 0, 0, 0.3) 0 0 10px;
  top: 200px;
  z-index: 2;
}

.main__content h1 {
  font-size: 5rem;
  font-weight: 800;
  margin: 0 0 10px 0;
  text-transform: uppercase;
}

.main__content p {
  line-height: 2.5rem;
  max-width: 700px;
}

.home__background {
  background: url(/assets/images/home.jpg) center center no-repeat;
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.border {
  background-color: #f8821a;
  bottom: 0;
  left: 0;
  padding-bottom: 10px;
  position: absolute;
  width: 100%;
}

.border__inside {
  height: 5px;
  background-color: #1f95a9;
}

.article {
  line-height: 1.5rem;
}

.article h2 {
  font-size: 2rem;
  margin: 40px 0 20px 0;
}

.article a {
  color: #f8821a;
  font-weight: 700;
  text-decoration: none;
}

.article ul,
.article ol {
  margin: 0 0 30px 30px;
}

.article li {
  margin: 0 0 6px 0;
}

.article a:hover {
  text-decoration: underline;
}

.article p {
  margin: 0 0 20px 0;
}

.default .wrap {
  margin: 0 auto;
  max-width: 1400px;
}

.mobile-menu {
  display: none;
  height: 30px;
  position: absolute;
  right: 20px;
  top: 12px;
  width: 30px;
}

.mobile-menu__trigger {
  height: 100%;
  width: 100%;
}


@media only screen and (max-width: 768px) {
  .mobile-menu {
    display: block;
  }

  .main__content {
    bottom: 0;
    padding: 30px;
    font-size: 1.2rem;
    top: auto;
  }

  .main__content h1 {
    font-size: 3rem;
  }

  .main__content p {
    line-height: 1.8rem;
    max-width: 100%;
  }
}