body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  border: 0;
  margin: 0;
  padding: 0;
}

html {
  background: #fff;
  height: 100%;
}

body {
  background: #f9f9f9;
  font-family: "Open Sans";
  font-size: 16px;
  min-height: 100%;
}

.main {
  display: -webkit-flex;
  display: flex;
  height: 100%;
}

.main video {
  height: 100%;
  object-fit: cover;
  width: 100vw;
}

.video-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  overflow: hidden;
}

.video-wrapper iframe {
  width: 100vw;
  height: 56.25vw;
  min-height: 100vh;
  min-width: 177.77vh;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.main__content {
  color: #fff;
  font-size: 1.3rem;
  padding: 50px;
  position: absolute;
  text-shadow: rgba(0, 0, 0, 0.3) 0 0 10px;
  top: 200px;
  z-index: 2;
}

.main__content h1 {
  font-size: 5rem;
  font-weight: 800;
  margin: 0 0 10px 0;
  text-transform: uppercase;
}

.main__content p {
  line-height: 2.5rem;
  max-width: 700px;
}

.home__background {
  background: url(/assets/images/home.jpg) center center no-repeat;
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.border {
  background-color: #f8821a;
  bottom: 0;
  left: 0;
  padding-bottom: 10px;
  position: absolute;
  width: 100%;
}

.border__inside {
  height: 5px;
  background-color: #1f95a9;
}

.article {
  line-height: 1.5rem;
}

.article h2 {
  font-size: 2rem;
  margin: 40px 0 20px 0;
}

.article a {
  color: #f8821a;
  font-weight: 700;
  text-decoration: none;
}

.article ul,
.article ol {
  margin: 0 0 30px 30px;
}

.article li {
  margin: 0 0 6px 0;
}

.article a:hover {
  text-decoration: underline;
}

.article p {
  margin: 0 0 20px 0;
}

.default .wrap {
  margin: 0 auto;
  max-width: 1400px;
}

.mobile-menu {
  display: none;
  height: 30px;
  position: absolute;
  right: 20px;
  top: 12px;
  width: 30px;
}

.mobile-menu__trigger {
  height: 100%;
  width: 100%;
}


@media only screen and (max-width: 768px) {
  .mobile-menu {
    display: block;
  }

  .main__content {
    bottom: 0;
    padding: 30px;
    font-size: 1.2rem;
    top: auto;
  }

  .main__content h1 {
    font-size: 3rem;
  }

  .main__content p {
    line-height: 1.8rem;
    max-width: 100%;
  }
}
.header {
  font-size: 1.2rem;
  line-height: 60px;
  position: relative;
  z-index: 5;
}

.header .wrap {}

.header__link {
  color: #fff;
  cursor: pointer;
  padding: 0 25px;
  position: relative;
  text-decoration: none;
  text-shadow: rgba(0, 0, 0, 0.3) 0 0 10px;
}

.header__link:hover {
  color: #f8821a;
}

.header__link--active {
  color: #f8821a;
}

.header__logo img {
  height: 100px;
  vertical-align: middle;
}

.header__menu {
  padding: 0 25px;
  text-align: right;
}

.desktop-menu {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.home .header {
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 2;
}

.home .desktop-menu {
  padding: 30px 20px;
}

.default .header {
  background: #fff;
  height: 100px;
  line-height: 100px;
}

.default .header__logo img {
  height: 60px;
}

.default .header__link {
  color: #000;
  font-size: 1rem;
  text-shadow: none;
}

.default .header__link--active {
  color: #f8821a;
}

@media only screen and (max-width: 768px) {
  .header__logo {
    display: inline-block;
  }

  .header__logo img {
    height: 70px;
  }

  .header__logo .header__link {
    padding: 0 0 0 20px;
  }

  .header__menu {
    background: rgba(255, 255, 255, 0.98);
    display: none;
    text-align: center;
  }

  .header__menu .header__link {
    border: 0;
    border-bottom: 1px solid #e8e8e8;
    color: #000;
    display: inline-block;
    margin-left: 0;
    padding: 0;
    width: 50%;
  }

  .header--open .header__menu {
    display: block;
  }

  .header--open .desktop-menu {
    box-shadow: 0px 2px 3px -1px #051c51;
    display: block;
  }

  .home .desktop-menu {
    padding: 20px 0;
  }

  .home .mobile-menu {
    top: 30px;
  }
}
.loader {
  margin: 100px;
  text-align: center;
}
.about {
  padding: 30px;
}

.about__header {
  border-bottom: 10px solid #f8821a;
  position: relative;
  padding-bottom: 56.25%;
}

.about__header__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.about__bio {
  padding: 30px;
  display: -webkit-flex;
  display: flex;
}

.about__bio__content {
  margin: 0 0 0 30px;
}

.about__bio__title {
  font-size: 3.75rem;
  font-weight: 800;
  margin: 10px 0 5px 0;
}

.about__bio__subtitle {
  font-size: 2rem;
  font-weight: 800;
  margin: 0 0 10px 0;
}

.about__bio__image {
  background-color: #ffffff;
  background-size: cover;
  border-radius: 999px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  height: 280px;
  max-height: calc(100vw - 40px);
  max-width: calc(100vw - 40px);
  width: 280px;
}

.about__bio__summary {
  line-height: 1.5rem;
  padding: 20px 0;
}

.about__bio__note {
  padding: 0 30px 50px 30px;
}

@media only screen and (max-width: 768px) {

  .about,
  .about__bio {
    padding: 20px;
  }

  .about__bio__content {
    margin: 0;
  }

  .about__bio__note {
    padding: 0 20px 50px 20px;
  }

  .about__bio {
    display: block;
  }
}
.category {
  padding: 30px;
}

.category__featured {
  border-bottom: 10px solid #f8821a;
  position: relative;
  padding-bottom: 56.25%;
}

.category__featured__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.category__title {
  font-size: 3.75rem;
  font-weight: 800;
  margin: 40px 0 30px 0;
}

.category__author {
  display: -webkit-flex;
  display: flex;
  margin: 0 0 50px 0;
}

.category__author__image {
  background-color: #ffffff;
  background-size: cover;
  border-radius: 999px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  height: 200px;
  width: 200px;
}

.category__author__summary {
  line-height: 1.5rem;
  padding: 20px 20px 20px 40px;
}

.category__post {
  background: #fff;
  box-shadow: 0 0 20px #eee;
  display: -webkit-flex;
  display: flex;
  margin: 0 0 30px 0;
  padding: 20px;
}

.category__post__link {
  color: #000;
  text-decoration: none;
}

.category__post__image {
  background: #f4f4f4;
  background-size: cover;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  height: 225px;
  width: 400px;
}

.category__post__content {
  padding: 20px 30px;
}

.category__post__content h2 {
  font-size: 2.25rem;
  font-weight: 800;
  margin: 0 0 10px 0;
}

.category__post__content p {
  font-size: 1rem;
}

.category__post__link:hover .category__post {
  box-shadow: 0 0 20px #ddd;
}


@media only screen and (max-width: 768px) {
  .category {
    padding: 20px;
  }

  .category__author {
    display: block;
  }

  .category__author__image {
    height: 150px;
    width: 150px;
  }

  .category__author__summary {
    padding: 20px 0;
  }

  .category__post {
    display: block;
  }

  .category__post__image {
    display: block;
    padding-bottom: 56.25%;
    height: auto;
    width: 100%;
  }
}
.page {
  padding: 30px;
}

.page__title {
  font-size: 3.75rem;
  font-weight: 800;
  margin: 10px 0 30px 0;
}

@media only screen and (max-width: 768px) {

  .page {
    padding: 20px;
  }
}
.author {
  padding: 30px;
}

.author__featured {
  border-bottom: 10px solid #f8821a;
  position: relative;
  padding-bottom: 56.25%;
}

.author__title {
  font-size: 3.75rem;
  font-weight: 800;
  margin: 40px 0 30px 0;
}

.author__image {
  background-size: cover;
  height: 600px;
  border-bottom: 10px solid #f8821a;
}
.post {
  padding: 30px;
}

.post__featured {
  border-bottom: 10px solid #f8821a;
  position: relative;
  padding-bottom: 56.25%;
}

.post__featured__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.post__title {
  font-size: 3.75rem;
  font-weight: 800;
  margin: 40px 0 30px 0;
}

.post__image {
  background-position: center;
  background-size: cover;
  height: 400px;
  border-bottom: 10px solid #f8821a;
}

.post__summary,
.post__content {
  margin: 0 0 100px 0;
}

.post__related__title {
  font-size: 2.5rem;
  font-weight: 800;
  margin: 0 0 20px 0;
}

@media only screen and (max-width: 768px) {
  .post {
    padding: 20px;
  }

  .post__title {
    font-size: 1.75rem;
  }

  .post__summary,
  .post__content {
    margin: 0 0 50px 0;
  }
}
