.about {
  padding: 30px;
}

.about__header {
  border-bottom: 10px solid #f8821a;
  position: relative;
  padding-bottom: 56.25%;
}

.about__header__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.about__bio {
  padding: 30px;
  display: flex;
}

.about__bio__content {
  margin: 0 0 0 30px;
}

.about__bio__title {
  font-size: 3.75rem;
  font-weight: 800;
  margin: 10px 0 5px 0;
}

.about__bio__subtitle {
  font-size: 2rem;
  font-weight: 800;
  margin: 0 0 10px 0;
}

.about__bio__image {
  background-color: #ffffff;
  background-size: cover;
  border-radius: 999px;
  flex-shrink: 0;
  height: 280px;
  max-height: calc(100vw - 40px);
  max-width: calc(100vw - 40px);
  width: 280px;
}

.about__bio__summary {
  line-height: 1.5rem;
  padding: 20px 0;
}

.about__bio__note {
  padding: 0 30px 50px 30px;
}

@media only screen and (max-width: 768px) {

  .about,
  .about__bio {
    padding: 20px;
  }

  .about__bio__content {
    margin: 0;
  }

  .about__bio__note {
    padding: 0 20px 50px 20px;
  }

  .about__bio {
    display: block;
  }
}