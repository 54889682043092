.author {
  padding: 30px;
}

.author__featured {
  border-bottom: 10px solid #f8821a;
  position: relative;
  padding-bottom: 56.25%;
}

.author__title {
  font-size: 3.75rem;
  font-weight: 800;
  margin: 40px 0 30px 0;
}

.author__image {
  background-size: cover;
  height: 600px;
  border-bottom: 10px solid #f8821a;
}