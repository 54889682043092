.header {
  font-size: 1.2rem;
  line-height: 60px;
  position: relative;
  z-index: 5;
}

.header .wrap {}

.header__link {
  color: #fff;
  cursor: pointer;
  padding: 0 25px;
  position: relative;
  text-decoration: none;
  text-shadow: rgba(0, 0, 0, 0.3) 0 0 10px;
}

.header__link:hover {
  color: #f8821a;
}

.header__link--active {
  color: #f8821a;
}

.header__logo img {
  height: 100px;
  vertical-align: middle;
}

.header__menu {
  padding: 0 25px;
  text-align: right;
}

.desktop-menu {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.home .header {
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 2;
}

.home .desktop-menu {
  padding: 30px 20px;
}

.default .header {
  background: #fff;
  height: 100px;
  line-height: 100px;
}

.default .header__logo img {
  height: 60px;
}

.default .header__link {
  color: #000;
  font-size: 1rem;
  text-shadow: none;
}

.default .header__link--active {
  color: #f8821a;
}

@media only screen and (max-width: 768px) {
  .header__logo {
    display: inline-block;
  }

  .header__logo img {
    height: 70px;
  }

  .header__logo .header__link {
    padding: 0 0 0 20px;
  }

  .header__menu {
    background: rgba(255, 255, 255, 0.98);
    display: none;
    text-align: center;
  }

  .header__menu .header__link {
    border: 0;
    border-bottom: 1px solid #e8e8e8;
    color: #000;
    display: inline-block;
    margin-left: 0;
    padding: 0;
    width: 50%;
  }

  .header--open .header__menu {
    display: block;
  }

  .header--open .desktop-menu {
    box-shadow: 0px 2px 3px -1px #051c51;
    display: block;
  }

  .home .desktop-menu {
    padding: 20px 0;
  }

  .home .mobile-menu {
    top: 30px;
  }
}